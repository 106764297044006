import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

window.addEventListener('load', e => {
  
  addAsteriskRequiredFields('.pa-contact-form-labels');

  splide();
  appendLabelToSearchIcon();
  addContentLogin();
  wooPaginationClasses();
});

function addAsteriskRequiredFields(formClass) {
  jQuery(formClass + ' *').each(function () {
    if (jQuery(this).attr('data-required_mark') == 'required' && !jQuery(this).hasClass('et_pb_contact_captcha')) {
      let x = jQuery(this).prev().html();
      jQuery(this)
        .prev()
        .html(x + ' <span class="blue">*</span>');
    }
  });
}

function splide() {
    if (document.getElementById('nuestrasMarcas')) {
        new Splide('#nuestrasMarcas', {
          pagination: false,
          type: 'loop',
          perPage: 3,
          gap: '3rem',
          autoplay: true,
          interval: 2000,
          padding: 32,
          perMove: 1,
          breakpoints: {
            768: {
              perPage: 2,
            },
            500: {
              perPage: 1,
              padding: 24,
            },
          },
        }).mount();
    }

    if (document.getElementById('otrasMarcas')) {
        new Splide('#otrasMarcas', {
          pagination: false,
          type: 'loop',
          perPage: 3,
          gap: '3rem',
          autoplay: true,
          interval: 2000,
          padding: 32,
          perMove: 1,
          breakpoints: {
            768: {
              perPage: 2,
            },
            500: {
              perPage: 1,
              padding: 24,
            },
          },
        }).mount(/* { AutoScroll } */);
    }

    if (document.getElementById('prodDest')) {
      new Splide('#prodDest', {
        pagination: false,
        arrows: false,
        type: 'loop',
        perPage: 5,
        autoScroll: {
          speed: 0.3,
        },
        breakpoints: {
          981: {
            perPage: 4,
          },
          768: {
            perPage: 3,
          },
          500: {
            perPage: 2,
          },
        },
      }).mount({ AutoScroll });
    }
}

function appendLabelToSearchIcon() {
    document.querySelector('.dgwt-wcas-ico-magnifier-handler').insertAdjacentHTML('afterend', '<p class="lmayo_search-text">Busqueda</p>');
    
}

function addContentLogin() {
    if (document.querySelector('.lmayo__woo-login .woocommerce-form-login') === null) {
        //document.querySelector('.lmayo__woo-login').classList.add('lmayo__manager');
      return;
    }

    let content =
      '<div class="welcome__container"><img class="welcome__img" src="https://dicom.leadexperience.com.mx/wp-content/uploads/2023/09/bienvenido-text.svg" alt="Bienvenido"><!--<p class="welcome__text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.</p>--></div>';

    document.querySelector('.lmayo__woo-login').insertAdjacentHTML('afterbegin', content);
    document.querySelector('.lmayo__woo-login .et_pb_code_inner').classList.add('welcome__login');
    document.getElementById('account-header').classList.add('lm_hide');
}

function wooPaginationClasses() {
    if (document.querySelector('.woocommerce-pagination')) {
   
      document.querySelectorAll('.dots').forEach(el => {
        el.closest('li').classList.add('lm_dots-list');
      });
    }
}
